import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';

var clock, whatsover='Burtaka Ltd';

const pot = {
    delay: 0,
};

function App() {

  const [show, setShow] = useState(true);
  const [trigger, setTrigger] = useState(1);

  pot.bang = ()=>{
      setTrigger(trigger+1);
  }

  useEffect(()=>{

      let overlapTill, toBeAdds;

      const cleanUp = ()=>{

          if(pot.delay>3){

              // console.log('cleanup', whatsover);
              if(overlapTill!==undefined&&(whatsover.length>overlapTill)){

                  whatsover = whatsover.slice(0, whatsover.length-1);
                  pot.bang();

              } else if(overlapTill!==undefined){

                  overlapTill = undefined;
                  if(toBeAdds){

                      let l = toBeAdds?.shift();
                      l&&(whatsover += l);

                  }

              } else if(toBeAdds){

                  let l = toBeAdds?.shift();
                  l&&(whatsover += l);
                  (toBeAdds.length == 0)&&(toBeAdds = undefined);
                  pot.bang();

              } else if(whatsover.length>11){
                  
                  // console.log(whatsover);
                  //setText(whatsover.slice(0, whatsover.length-1));
                  whatsover = whatsover.slice(0, whatsover.length-1);
                  pot.bang();
                  return setTimeout(textNavigate, 50);

              } else if(whatsover!==(pot.target||'Burtaka Ltd')){

                  return setTimeout(textNavigate, 50);

              } else {

                  pot.delay = 0;

              };

              return setTimeout(cleanUp, 50);

          } else return setTimeout(cleanUp, 3000);

      }

      cleanUp();

      const textNavigate = (target = 'Burtaka Ltd')=>{

          const temp = [...target];
          const current = [...whatsover];

          overlapTill = ([...new Array(Math.max(temp.length, current.length))]).findIndex((l, i)=>(current[i]!==temp[i])); //([...whatsover]).findIndex((l, i)=>((console.log(l, temp[i], i, temp[i]!==l)||true)&&temp[i]!==l));
          toBeAdds = (overlapTill !== temp?.length)&&temp.slice(overlapTill, temp?.length);

          // console.log('ot', overlapTill, 'a', toBeAdds, temp);

          cleanUp();

      }

      document.addEventListener('keydown', (e)=>{

          let key = e.key;
          if(key=='Backspace'){

              whatsover = whatsover.slice(0, whatsover.length-1);
              pot.bang();

          } else if(key=='Shift'){

          } else {
              whatsover += (e.key);
              pot.bang();
              pot.delay = 0;
          }

      }, false);

      pot.clock = setInterval(()=>{
          pot.delay++;
          pot.show = !pot.show;
          setShow(pot.show);
      }, 500);

      return(()=>{
          clearInterval(pot.clock);
      });

  }, []);

  return (
    <div className="App">
      {trigger&&<div style={{ position:'absolute', top:'6rem', left:'6rem'}} className='paracontainer' >
          <div className='text' style={{fontSize:40}}>百橙科技</div>
          <div style={{color:'white', backgroundColor:'#FA5600', 
            fontSize:40, maxWidth:'80vw', textAlign:'left', wordBreak:'break-all', fontWeight:'bold', paddingLeft:5, paddingRight:5}} className='xcontainer lightup'>
              <div>{whatsover}{show?'_':''}</div>
          </div>
          <div style={{color:'black', fontStyle:'italic', fontWeight:'lighter', margin:10}} className='paracontainer'>
              <div>人工智能应用落地</div>
              <div>自动化系统/应用/软件开发</div>
          </div>
      </div>}
      <div style={{ position:'absolute', bottom:'18rem', right:'6rem', display:'flex', flexDirection:'column', alignItems:'start'}} >
          <div style={{color:'white', fontStyle:'italic', backgroundColor: '#FA5600', padding:10,
              fontWeight:'lighter', alignItems:'end'}} className='paracontainer lightup'>
              <div>Software / System</div>
              <div>Customization Service.</div>
          </div>
      </div>
      <div style={{ position:'absolute', bottom:0, right:0, margin:'6rem', display:'flex', flexDirection:'column', alignItems:'start'}} >
          <div style={{color:'rgba(0,0,0,0.3)', fontWeight:'lighter', fontSize:16, alignItems:'end'}} className='paracontainer'>
              <div>
                百橙科技（深圳）有限公司
              </div>
              <div style={{textAlign: 'right'}}>
                深圳市南山区科智西路5号科苑西25栋6楼西交深圳孵化中心
              </div>
              
              <div>
                欢迎咨询 13316533167
              </div>
              <a className='plain' href="mailto:support@burtaka.com">[EMAIL]</a>
              <br />
              <a className='plain' href="https://beian.miit.gov.cn/">
                粤ICP备2021082971号
              </a>
          </div>
      </div>
    </div>
  );
}

export default App;
